import { FormControl } from '@angular/forms';

export interface Evenement {
  id: string;
  nom: string;
  idAparte: number;
  date: Date;
  nomClient: string;
  statutRapprochementOk: boolean;
  datePlusVieuxReglementNonRapproche: Date;
  totalReverse: number;
  statutEvenement: StatutEvenement;
  montantDu: number;
  detail: string;
  montantTotal: number;
}

export interface EvenementDataTable {
  items: Evenement[];
  page: {
    current: number;
    size: number;
  };
  totalCount: number;
}

export interface EvenementFilterForm {
  numeroPage: FormControl<number>;
  nbrParPage: FormControl<number>;
  orderingField: FormControl<string>;
  orderingDirection: FormControl<number>;
  codeClients: FormControl<string[]>;
  rechercheEvenement: FormControl<string>;
  aRedditionnerUniquement: FormControl<boolean>;
  filtreDateDebut: FormControl<string>;
  filtreDateFin: FormControl<string>;
}

export interface EvenementFilter {
  numeroPage: number;
  nbrParPage: number;
  orderingField: string;
  orderingDirection: number;
  codeClients: string[];
  rechercheEvenement: string;
  aRedditionnerUniquement: boolean;
  filtreDateDebut: string;
  filtreDateFin: string;
}

export interface Client {
  codeClient: string;
  libelleClient: string;
}

export enum StatutEvenement {
  Acompte = 'Acompte',
  EnCours = 'EnCours',
  ARedditionner = 'ARedditionner',
  AControler = 'AControler',
  Reverse = 'Reverse',
}

export interface RedditionStatut {
  idReddition: string;
  statutReddition: string;
}

export interface AcompteStatut {
  idAcompte: string;
  statutAcompte: string;
}

export interface RedditionSuccess {
  idReddition: string;
  montantTotalCommissionsHT: number;
  montantTotalCommissionsTTC: number;
  montantReverseAuClient: number;
}

export interface RedditionCreation {
  idEvenement: string;
  idCompteBancaire: string;
}

export interface AcompteSuccess {
  idAcompte: string;
  montantReverseAuClient: number;
}

export interface AcompteCreation {
  idEvenement: string;
  montantAcompte: number;
  idCompteBancaire: string;
}

export interface DocAction {
  id: string;
  action: PdfActon;
}

export enum PdfActon {
  telecharger,
  visionner,
}
